import { Banner } from '@/types/banner';
import { api } from './api';

const baseUrl = '/banners';

const bannerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanners: builder.query<{ banners: Banner[] }, void>({
      query: () => baseUrl,
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = bannerApi.endpoints;
export const { useGetBannersQuery } = bannerApi;
