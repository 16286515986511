import { useDeleteTrophyMutation } from '@/api/trophies';
import { Trophy } from '@/types/trophies';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';

export const DeleteTrophyButton: FC<{ trophyId: Trophy['id'] }> = ({
  trophyId,
}) => {
  const [deleteTrophy] = useDeleteTrophyMutation();
  return (
    <p
      onClick={() => deleteTrophy({ trophyId })}
      style={{ color: 'red', cursor: 'pointer' }}
      title="Delete trophy"
    >
      <DeleteOutlined /> Delete
    </p>
  );
};
