import React, { useState } from 'react';
import { Button, Form, Select, notification } from 'antd';
import { Header } from '@/layout/Header';
import { useGetDemoUploadSignedUrlMutation } from '@/api/demo';
import { Match } from '@/types/match';
import { useGetMatchesForDemoUploadQuery } from '@/api/matches';
import { DemoUploader } from './DemoUploader';
import { useUploadDemoToS3Mutation } from '@/api/s3-demo';
import { Avatar, Space } from 'antd';
import { MEDIA_FILE_URL } from '@/CONSTS';

const { Option } = Select;

type FieldType = {
  matchId: Match['id'];
  demo: { file: File };
};

export const UploadDemo: React.FC = () => {
  const { data: matchesData } = useGetMatchesForDemoUploadQuery();
  const [getSignedUrl] = useGetDemoUploadSignedUrlMutation();
  const [uploadDemo] = useUploadDemoToS3Mutation();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: FieldType) => {
    setLoading(true);
    const response = await getSignedUrl({
      matchId: values.matchId,
      fileName: values.demo.file.name,
    });

    if ((response as any)?.error) {
      setLoading(false);
      const error = (response as any)?.error.data;

      notification.error({
        description: error.error,
        message: error.message,
      });
      return;
    }

    const signedUrl = (response as any)?.data?.signedUrl ?? '';
    if (!signedUrl) return;

    uploadDemo({ body: values.demo.file, signedUrl }).then(() => {
      setLoading(false);
      window.location.reload();
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false);
    notification.error({
      description: errorInfo.error.data.error,
      message: errorInfo.error.data.message,
    });
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 850 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Upload demo" />
        </Form.Item>

        <Form.Item
          label="Match"
          name="matchId"
          rules={[{ required: true, message: 'Please select match' }]}
        >
          <Select placeholder="Select match" style={{ height: 76 }}>
            {matchesData?.matches.map(({ id, date, matchTeam }) => {
              const team1 = matchTeam[0]!.team;
              const team2 = matchTeam[1]!.team;
              const team1Logo = `${MEDIA_FILE_URL}/${team1?.logo}`;
              const team2Logo = `${MEDIA_FILE_URL}/${team2?.logo}`;

              return (
                <Option value={id} key={id}>
                  <Space>
                    <Avatar
                      shape="square"
                      size={64}
                      src={team1Logo}
                      style={{ marginRight: 8 }}
                    />
                    {team1?.name}
                    vs{' '}
                    <Avatar
                      shape="square"
                      size={64}
                      src={team2Logo}
                      style={{ marginRight: 8 }}
                    />
                    {team2?.name}
                  </Space>
                  {' - '}
                  {new Date(date).toLocaleString()}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="demo">
          <DemoUploader loading={loading} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="default" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
