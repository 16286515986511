import { TrophyPlayersSelector } from './TrophyPlayerSelector';
import { useState } from 'react';
import { useGetUsersByTrophyQuery } from '@/api/trophies';
import { TrophyPlayersTable, TrophyUserDataType } from './TrophyPlayersTable';
import { TrophySelector } from './TrophySelector';

export const TrophyOwners = () => {
  const [selectedTrophyId, setSelectedTrophyId] = useState<string | null>(null);

  const { data: usersData } = useGetUsersByTrophyQuery(
    { trophyId: selectedTrophyId! },
    { skip: !selectedTrophyId },
  );

  const columnData = usersData?.users.map(
    (user, i): TrophyUserDataType => ({
      id: user.id,
      key: user.id,
      index: i + 1,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      nickname: user.nickname,
      role: user.role,
      birthday: user.birthday,
      avatar: user.avatar,
    }),
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TrophySelector
          setSelectedTrophyId={setSelectedTrophyId}
          header="Player Trophy"
        />
        {selectedTrophyId && (
          <TrophyPlayersSelector trophyId={selectedTrophyId} />
        )}
      </div>
      <TrophyPlayersTable columnData={columnData ?? []} />
    </div>
  );
};
