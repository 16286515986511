import { Form } from 'antd';
import { Header } from '@/layout/Header';
import { Select } from 'antd';
import { useGetTrophiesQuery } from '@/api/trophies';
import { Avatar, Space } from 'antd';
import { Trophy } from '@/types/trophies';
import { FC } from 'react';
const { Option } = Select;
import { ImgPrefix } from '@/layout/Layout';

type TrophyMembersTrophySelector = {
  setSelectedTrophyId: (trophyId: Trophy['id']) => void;
  header: string;
};

export const TrophySelector: FC<TrophyMembersTrophySelector> = ({
  setSelectedTrophyId,
  header,
}) => {
  const { data: trophyData } = useGetTrophiesQuery();

  const trophiesOptions =
    trophyData?.trophies.map(({ id, image, name }) => ({
      value: id,
      searchString: name!,
      label: (
        <Space>
          <Space>
            <Avatar
              shape="square"
              size={64}
              src={`${ImgPrefix}${image}`}
              style={{ marginRight: 8 }}
            />
          </Space>
          {name}
        </Space>
      ),
    })) ?? [];

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '40%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header={header} />
        </Form.Item>

        <Form.Item
          label="Select Trophy"
          name="trophy"
          rules={[{ required: true, message: 'Please select trophy' }]}
        >
          <Select
            placeholder="Select trophy"
            style={{ height: 76 }}
            onChange={(trophyId: Trophy['id']) => {
              setSelectedTrophyId(trophyId);
            }}
            showSearch
            filterOption={filterOption}
            options={trophiesOptions}
          ></Select>
        </Form.Item>
      </Form>
    </div>
  );
};
