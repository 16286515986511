import { api } from './api';
import { Trophy } from '@/types/trophies';
import { User } from '@/types/user';
import { Team } from '@/types/team';

const baseUrl = '/trophies';

const trophiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTrophies: builder.query<{ trophies: Trophy[] }, void>({
      query: () => baseUrl,
      providesTags: ['Trophies'],
    }),
    createTrophy: builder.mutation<{ trophies: Trophy }, FormData>({
      invalidatesTags: ['Trophies'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    uploadLogo: builder.mutation<void, { file: File }>({
      invalidatesTags: ['Trophies'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    deleteTrophy: builder.mutation<
      { trophy: Trophy },
      { trophyId: Trophy['id'] }
    >({
      invalidatesTags: ['Trophies'],
      query: ({ trophyId }) => ({
        url: `${baseUrl}/${trophyId}`,
        method: 'DELETE',
      }),
    }),
    editTrophy: builder.mutation<
      { trophies: Trophy },
      { formData: FormData; trophyId: Trophy['id'] }
    >({
      invalidatesTags: ['Trophies'],
      query: ({ trophyId, formData }) => ({
        url: `${baseUrl}/${trophyId}`,
        method: 'PATCH',
        body: formData,
      }),
    }),
    getTrophyById: builder.query<
      { trophy: Trophy },
      { trophyId: Trophy['id'] }
    >({
      query: ({ trophyId }) => `${baseUrl}/${trophyId}`,
      providesTags: ['Trophies'],
    }),
    getPlayerByTrophyId: builder.query<
      { trophyPlayers: User[] },
      { trophyId: Trophy['id'] | null }
    >({
      query: ({ trophyId }) => `${baseUrl}/${trophyId}`,
    }),
    togglePlayerTrophy: builder.mutation<
      void,
      { trophyId: Trophy['id']; playerId: User['id'] }
    >({
      invalidatesTags: ['Trophies', 'User'],
      query: ({ trophyId, playerId }) => ({
        url: `${baseUrl}/${trophyId}/toggle-player-assignment/${playerId}`,
        body: { trophyId, playerId },
        method: 'PATCH',
      }),
    }),
    toggleTeamTrophy: builder.mutation<
      void,
      { trophyId: Trophy['id']; teamId: Team['id'] }
    >({
      invalidatesTags: ['Trophies', 'Team'],
      query: ({ trophyId, teamId }) => ({
        url: `${baseUrl}/${trophyId}/toggle-team-assignment/${teamId}`,
        body: { trophyId, teamId },
        method: 'PATCH',
      }),
    }),
    getTeamByTrophyId: builder.query<
      { trophyTeam: Team[] },
      { trophyId: Trophy['id'] | null }
    >({
      query: ({ trophyId }) => `${baseUrl}/${trophyId}`,
    }),
    getUsersByTrophy: builder.query<{ users: User[] }, { trophyId: string }>({
      query: ({ trophyId }) => `${baseUrl}/${trophyId}/users`,
    }),
    getTeamsByTrophy: builder.query<{ teams: Team[] }, { trophyId: string }>({
      query: ({ trophyId }) => `${baseUrl}/${trophyId}/teams`,
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = trophiesApi.endpoints;
export const {
  useGetTrophiesQuery,
  useCreateTrophyMutation,
  useUploadLogoMutation,
  useDeleteTrophyMutation,
  useEditTrophyMutation,
  useGetTrophyByIdQuery,
  useGetPlayerByTrophyIdQuery,
  useTogglePlayerTrophyMutation,
  useToggleTeamTrophyMutation,
  useGetTeamByTrophyIdQuery,
  useGetTeamsByTrophyQuery,
  useGetUsersByTrophyQuery,
} = trophiesApi;
