import React from 'react';
import { Layout } from './layout/Layout';
import { useGetCurrentUserQuery } from './api/users';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import * as reactRouterDom from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { Users } from './components/user/Users';
import { Players } from './components/user/Players';
import { Coaches } from './components/user/Coaches';
import { AddUser } from './components/user/AddUser';
import { EditTeam } from './components/team/EditTeam';
import { Teams } from './components/team/Teams';
import { Offers } from './components/offers/Offers';
import { EditOffer } from './components/offers/EditOffer';
import { AddTrophy } from './components/trophies/AddTrophy';
import { EditTrophy } from './components/trophies/EditTrophy';
import { EditMap } from './components/maps/EditMap';
import { Trophies } from './components/trophies/Trophies';
import { TrophyOwners } from './components/trophies/TrophiesPlayers';
import { TrophyTeam } from './components/trophies/TrophiesTeams';
import { AddTeam } from './components/team/AddTeam';
import { AddMaps } from './components/maps/AddMaps';
import { Maps } from './components/maps/Maps';
import { Matches } from './components/match/Matches';
import { AddMatch } from './components/match/AddMatch';
import { UploadDemo } from './components/demo/UploadDemo';
import { TeamMembers } from './components/team/TeamPlayers';
import { AddBanner } from './components/banner/AddBanner';
import { AddOffer } from './components/offers/AddOffer';
import { EditMatch } from './components/match/EditMatch';
import { Banners } from './components/banner/Banners';
import { EditBanner } from './components/banner/EditBanner';
import { EditUser } from './components/user/EditUser';

const VITE_API_URL = import.meta.env.VITE_API_URL;
const VITE_APP_URL = import.meta.env.VITE_APP_URL;
const APP_NAME = 'Gamer Insight';
SuperTokens.init({
  appInfo: {
    appName: APP_NAME,
    apiDomain: VITE_API_URL,
    websiteDomain: VITE_APP_URL,
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },
  recipeList: [EmailPassword.init(), Session.init()],
});

export const App: React.FC = () => {
  useGetCurrentUserQuery();
  return (
    <SuperTokensWrapper>
      <BrowserRouter>
        <Routes>
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
            EmailPasswordPreBuiltUI,
          ])}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="users">
              <Route path="all" element={<Users />}></Route>
              <Route path="players" element={<Players />}></Route>
              <Route path="coaches" element={<Coaches />}></Route>
              <Route path="create" element={<AddUser />}></Route>
              <Route path="edit/:id" element={<EditUser />}></Route>
            </Route>
            <Route path="teams">
              <Route path="all" element={<Teams />}></Route>
              <Route path="create" element={<AddTeam />}></Route>
              <Route path="edit/:id" element={<EditTeam />}></Route>
              <Route path="members" element={<TeamMembers />}></Route>
            </Route>
            <Route path="matches">
              <Route path="all" element={<Matches />}></Route>
              <Route path="create" element={<AddMatch />}></Route>
              <Route path="edit/:id" element={<EditMatch />}></Route>
            </Route>
            {/* <Route path="events">
              <Route path="all" element={<Events />}></Route>
              <Route path="create" element={<AddEvent />}></Route>
            </Route> */}
            <Route path="demo">
              <Route path="upload" element={<UploadDemo />}></Route>
            </Route>
            <Route path="offers">
              <Route path="all" element={<Offers />}></Route>
              <Route path="create" element={<AddOffer />}></Route>
              <Route path="edit/:id" element={<EditOffer />}></Route>
            </Route>
            <Route path="trophies">
              <Route path="all" element={<Trophies />}></Route>
              <Route path="create" element={<AddTrophy />}></Route>
              <Route path="trophies-players" element={<Trophies />}></Route>
              <Route path="trophy-players" element={<TrophyOwners />}></Route>
              <Route path="trophy-teams" element={<TrophyTeam />}></Route>
              <Route path="edit/:id" element={<EditTrophy />}></Route>
            </Route>
            <Route path="banners">
              <Route path="all" element={<Banners />}></Route>
              <Route path="create" element={<AddBanner />}></Route>
              <Route path="edit/:id" element={<EditBanner />}></Route>
            </Route>
            <Route path="maps">
              <Route path="all" element={<Maps />}></Route>
              <Route path="create" element={<AddMaps />}></Route>
              <Route path="edit/:id" element={<EditMap />}></Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </SuperTokensWrapper>
  );
};
