import React, { useState } from 'react';
import { Button, Form, Input, Checkbox, Space } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate } from 'react-router-dom';
import { useCreateOffersMutation } from '@/api/offers';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

type FieldType = {
  id: string;
  name: string;
  description: string;
  isBanner: string;
  imageSmall: string;
  imageLarge: string;
  url: string;
  active: string;
  createdAt: Date;
  updatedAt: Date;
};

export const AddBanner: React.FC = () => {
  const [createBanner] = useCreateOffersMutation();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name || '');
    formData.append('description', values.description || '');
    formData.append('url', values.url || '');
    formData.append('isBanner', String(true));
    formData.append('active', String(active));
    formData.append('imageSmall', values.imageSmall || '');
    formData.append('imageLarge', values.imageLarge || '');

    await createBanner(formData);
    navigate('/banners/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const changeIsActive = (e: CheckboxChangeEvent) => {
    setActive(e.target.checked);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Add banner" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: false, message: 'Please input offer name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Description"
          name="description"
          rules={[
            { required: false, message: 'Please input offer description' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Link url"
          name="url"
          rules={[{ required: false, message: 'Please input offer url' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Is active"
          name="active"
          rules={[{ required: false, message: 'Please input offer url' }]}
        >
          <Checkbox onChange={changeIsActive} checked={active} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="imageSmall">
          <p>Small banner</p>
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="imageLarge">
          <p>Large banner</p>
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
