import { Avatar, Button, Flex, Pagination, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Role } from '@/types/role.type';
import { DeleteUserButton } from './DeleteUserButton';

export type UserDataType = {
  id: string;
  key: string;
  index: number;
  email: string;
  name: string;
  nickname: string;
  steamId: string;
  role: Role;
  avatar: string;
  birthday: string;
};

const columns: ColumnsType<UserDataType> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    width: 120,

    render: (avatar) => (
      <Space>
        <Avatar size={64} src={avatar} />
      </Space>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    render: (role) => <p>{role}</p>,
  },
  {
    title: 'Steam ID',
    dataIndex: 'steamId',
    render: (steamId) => <p>{steamId}</p>,
  },
  {
    title: 'Birthday',
    dataIndex: 'birthday',
    render: (birthday) => (
      <p>
        {birthday
          ? new Date(birthday).toLocaleString('pl').split(', ')[0]
          : '-----'}
      </p>
    ),
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (mapId) => (
      <a href={`/users/edit/${mapId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit User
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
];

export const UsersTable = ({
  columnData,
  pagination,
  setPage,
  setPageSize,
}: {
  columnData: UserDataType[];
  pagination: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
}) => {
  return (
    <>
      <Table
        scroll={{ x: 1500, y: 1000 }}
        columns={columns}
        dataSource={[...columnData]}
        pagination={false}
      />
      <Pagination
        defaultCurrent={6}
        total={pagination}
        onChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};
