import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 30,
  tagTypes: [
    'User',
    'Team',
    'Match',
    'Event',
    'Team-Players',
    'Trophies',
    'Trophy-User',
    'Trophy-Team',
    'Map',
    'Offers',
  ],
});

export const s3Api = createApi({
  reducerPath: 's3-api',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: () => ({}),
});
