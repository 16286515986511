import { useGetUserByIdQuery, useUpdateUserMutation } from '@/api/users';
import { Role } from '@/types/role.type';
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Switch,
} from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { Header } from '@/layout/Header';
import { LeftOutlined } from '@ant-design/icons';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { DeleteUserButton } from './DeleteUserButton';
const { Option } = Select;
import dayjs from 'dayjs';
import { useToggleUserStatusMutation } from '@/api/user-assignemtn';

type UserFieldType = {
  firstName: string;
  lastName: string;
  nickname: string;
  steamId: string;
  email: string;
  role: Role;
  avatar: 'ACTIVE' | 'INACTIVE';
  birthday: string;
};

export const EditUser: FC = () => {
  const [toggleUserStatusMutation] = useToggleUserStatusMutation();

  const navigate = useNavigate();
  let { id } = useParams();
  const userId = !!id ? id : '';

  const { data: userData, isLoading } = useGetUserByIdQuery(
    { userId },
    { skip: !userId },
  );
  const [updateUser] = useUpdateUserMutation();

  console.log(userData);

  const onFinish = async (values: UserFieldType) => {
    const formData = new FormData();
    formData.append('firstName', values?.firstName ?? '');
    formData.append('lastName', values?.lastName ?? '');
    formData.append('email', values?.email ?? '');
    formData.append('role', values?.role ?? '');
    formData.append('nickname', values?.nickname ?? '');
    formData.append('steamId', values?.steamId ?? '');
    formData.append('avatar', values?.avatar ?? '');
    formData.append('birthday', values?.birthday ?? '');

    await updateUser({ userId, body: formData });
    navigate('/users/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/users/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all users
            </div>
          </Button>
        </Flex>
      </a>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        fields={[
          {
            name: 'firstName',
            value: userData?.user.firstName,
          },
          {
            name: 'lastName',
            value: userData?.user.lastName,
          },
          {
            name: 'email',
            value: userData?.user.email,
          },
          {
            name: 'role',
            value: userData?.user.role,
          },
          {
            name: 'nickname',
            value: userData?.user.nickname,
          },
          {
            name: 'steamId',
            value: userData?.user.steamId,
          },
          {
            name: 'birthday',
            value: !!userData?.user?.birthday
              ? dayjs(userData?.user.birthday)
              : dayjs(new Date()),
          },
        ]}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Update user" />
        </Form.Item>

        <Form.Item<UserFieldType>
          label="First Name"
          name="firstName"
          rules={[{ required: false, message: 'Please input first name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<UserFieldType>
          label="Last Name"
          name="lastName"
          rules={[{ required: false, message: 'Please input last name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<UserFieldType>
          label="Nickname"
          name="nickname"
          rules={[{ required: false, message: 'Please input nickname' }]}
        >
          <Input type="nickname" />
        </Form.Item>

        <Form.Item<UserFieldType>
          label="Email"
          name="email"
          rules={[{ required: false, message: 'Please input email' }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item<UserFieldType>
          label="Steam ID"
          name="steamId"
          rules={[{ required: false, message: 'Please input Steam ID' }]}
        >
          <Input type="steamId" />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: false, message: 'Please input role' }]}
        >
          <Select placeholder="Select role">
            {Object.values(Role).map((role) => (
              <Option value={role} key={role}>
                {role}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<UserFieldType>
          label="Birthday"
          name="birthday"
          rules={[{ required: true, message: 'Please select birthday' }]}
        >
          <DatePicker format={'DD-MM-YYYY'} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="avatar">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="default" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      {!isLoading && (
        <Space style={{ gap: 490 }}>
          <DeleteUserButton userId={userId} />
          {['COACH', 'PLAYER'].includes(userData!.user.role) && (
            <Space>
              <p>Is active: </p>
              <Switch
                defaultChecked={userData?.user.status === 'ACTIVE'}
                onChange={() => {
                  toggleUserStatusMutation({ userId: userData!.user.id });
                }}
              />
            </Space>
          )}
        </Space>
      )}
    </>
  );
};
