import React from 'react';
import { Button, Form, Input, Flex } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { useGetOfferByIdQuery, useEditOfferMutation } from '@/api/offers';
import { LeftOutlined } from '@ant-design/icons';

type FieldType = {
  id: string;
  name: string;
  description: string;
  active: string;
  imageSmall: string;
  imageLarge: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
};

export const EditBanner: React.FC = () => {
  const navigate = useNavigate();
  const [updateOffer] = useEditOfferMutation();

  let { id } = useParams();
  const offerId = id ? id : '';

  const { data: offersData } = useGetOfferByIdQuery({
    offerId: offerId as string,
  });

  const offers = (offersData?.offer || {}) as FieldType;

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name || '');
    formData.append('description', values.description || '');
    formData.append('url', values.url || '');
    formData.append('imageSmall', values.imageSmall ?? '');
    formData.append('imageLarge', values.imageLarge ?? '');

    await updateOffer({ offerId, formData });
    navigate('/banners/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/banners/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all banners
            </div>
          </Button>
        </Flex>
      </a>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        fields={[
          { name: ['description'], value: offers.description },
          { name: ['name'], value: offers.name },
          { name: ['url'], value: offers.url },
        ]}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Edit banner" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          initialValue={offers.name}
          name="name"
          rules={[{ required: false, message: 'Please input offer name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Description"
          name="description"
          initialValue={offers.description}
          rules={[
            { required: false, message: 'Please input offer description' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Link url"
          name="url"
          initialValue={offers.url}
          rules={[{ required: false, message: 'Please input offer url' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="imageSmall">
          <p>Small offer</p>
          <ImageUploader currentFileImgUrl={''} label="Small image" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="imageLarge">
          <p>Large offer</p>
          <ImageUploader currentFileImgUrl={''} label="Large image" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
