import React from 'react';
import { Button, Form, Input, Flex, Space, Avatar } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditTeamMutation, useGetTeamByIdQuery } from '@/api/teams';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { ImgPrefix } from '@/layout/Layout';
import { LeftOutlined } from '@ant-design/icons';

type FieldType = {
  name: string;
  logo: string;
};

export const EditTeam: React.FC = () => {
  let { id } = useParams();
  const { data: teamData } = useGetTeamByIdQuery({ teamId: id as string });
  const team = (teamData?.team || {}) as FieldType;
  const navigate = useNavigate();
  const [editTeam] = useEditTeamMutation();
  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('logo', values.logo);
    formData.append('name', values.name);

    await editTeam({ teamId: id as string, formData });
    navigate('/teams/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/teams/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all teams
            </div>
          </Button>
        </Flex>
      </a>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        fields={[{ name: ['name'], value: team.name }]}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Edit team" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          initialValue={team.name}
          rules={[{ required: true, message: 'Please input team name' }]}
        >
          <Input />
        </Form.Item>
        {team.logo && (
          <div style={{ marginLeft: '25%', marginBottom: '30px' }}>
            <p style={{ marginBottom: '10px' }}>Current team logo: </p>
            <Space>
              <Avatar
                shape="square"
                size={130}
                src={`${ImgPrefix}${team.logo}`}
              />
            </Space>
          </div>
        )}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="logo">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
