import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { Role } from '@/types/role.type';
import { Header } from '@/layout/Header';
import { useCreateUserMutation } from '@/api/users';
import { useNavigate } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';

const { Option } = Select;

type FieldType = {
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  avatar: string;
};

export const AddUser: React.FC = () => {
  const [createUser] = useCreateUserMutation();
  const navigate = useNavigate();

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('email', values.email);
    formData.append('role', values.role);
    !!values?.avatar && formData.append('avatar', values.avatar);

    await createUser(formData);
    navigate('/users/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Create user" />
        </Form.Item>

        <Form.Item<FieldType>
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please input first name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input last name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input email' }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: 'Please input role' }]}
        >
          <Select placeholder="Select role">
            {Object.values(Role).map((role) => (
              <Option value={role} key={role}>
                {role}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="avatar">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="default" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
