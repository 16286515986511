import { api } from './api';
import { Map } from '@/types/maps';

const baseUrl = '/maps';

const mapApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMaps: builder.query<{ maps: Map[] }, void>({
      query: () => baseUrl,
    }),
    getMapById: builder.query<{ map: Map }, { mapId: Map['id'] }>({
      query: ({ mapId }) => `${baseUrl}/${mapId}`,
      providesTags: ['Map'],
    }),
    createMap: builder.mutation<{ maps: Map }, FormData>({
      invalidatesTags: ['Map'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    deleteMap: builder.mutation<{ map: Map }, { mapId: Map['id'] }>({
      invalidatesTags: ['Map'],
      query: ({ mapId }) => ({
        url: `${baseUrl}/${mapId}`,
        method: 'DELETE',
      }),
    }),
    editMap: builder.mutation<
      { map: Map },
      { formData: FormData; mapId: Map['id'] }
    >({
      invalidatesTags: ['Map'],
      query: ({ mapId, formData }) => ({
        url: `${baseUrl}/${mapId}`,
        method: 'PATCH',
        body: formData,
      }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = mapApi.endpoints;
export const {
  useGetMapsQuery,
  useGetMapByIdQuery,
  useCreateMapMutation,
  useDeleteMapMutation,
  useEditMapMutation,
} = mapApi;
