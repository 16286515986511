import { Avatar, Button, Flex, Pagination, Space, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Role } from '@/types/role.type';
import { User } from '@/types/user';
import { useToggleUserStatusMutation } from '@/api/user-assignemtn';

export type DataType = {
  id: string;
  key: string;
  index: number;
  email: string;
  name: string;
  nickname: string;
  role: Role;
  avatar: string;
  birthday: string;
  // status?: { status: 'ACTIVE' | 'INACTIVE'; id: string };
};

const columns =
  (): // togglePlayerStatusMutation: ({ userId }: { userId: User['id'] }) => void,
  ColumnsType<DataType> => [
    {
      title: 'Index',
      dataIndex: 'index',
      render: (text) => <p>{text}</p>,
      width: 80,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      width: 120,

      render: (avatar) => (
        <Space>
          <Avatar size={64} src={avatar} />
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (role) => <p>{role}</p>,
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
      render: (birthday) => (
        <p>{birthday ? new Date(birthday).toDateString() : '-----'}</p>
      ),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: ({ status }) => <p>{status}</p>,
    // },
    // {
    //   title: 'Make active',
    //   dataIndex: 'status',
    //   render: ({ id, status }) => {
    //     return (
    //       <Switch
    //         defaultChecked={status === 'ACTIVE'}
    //         onChange={() => {
    //           togglePlayerStatusMutation({ userId: id });
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: 'Edit',
      dataIndex: 'key',
      render: (mapId) => (
        <a href={`/users/edit/${mapId}`}>
          <Flex gap="small" wrap="wrap">
            <Button
              type="link"
              style={{ backgroundColor: 'blue', color: 'white' }}
            >
              Edit User
            </Button>
          </Flex>
        </a>
      ),
      width: 190,
    },
  ];

export const PlayersTable = ({
  columnData,
  pagination,
  setPage,
  setPageSize,
}: {
  columnData: DataType[];
  pagination: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
}) => {
  // const [toggleUserStatusMutation] = useToggleUserStatusMutation();

  return (
    <>
      <Table
        scroll={{ x: 1500, y: 1000 }}
        columns={columns()}
        dataSource={[...columnData]}
        pagination={false}
      />
      <Pagination
        defaultCurrent={6}
        total={pagination}
        onChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};
