import Cookies, { CookieAttributes } from 'js-cookie';
import { createSlice } from '@reduxjs/toolkit';
import { userEndpoints } from '@/api/users';
import { User } from '@/types/user';

export type CredentialsStore = {
  user?: User;
};

const initialState: CredentialsStore = {
  user: undefined,
};

const credentialsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(
      userEndpoints.getCurrentUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
      },
    );
  },
  name: 'credentials',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = undefined;
    },
  },
});

export const { logout } = credentialsSlice.actions;
export const credentialsReducer = credentialsSlice.reducer;
