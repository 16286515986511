import { Avatar, Space, Table, Flex, Button, Pagination } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteTeamButton } from './DeleteTeamButton';
import { MEDIA_FILE_URL } from '@/CONSTS';

export type DataType = {
  key: string;
  index: number;
  name: string;
  logo: string;
};

const columns: ColumnsType<DataType> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    width: 200,

    render: (logo) => (
      <Space>
        <Avatar
          shape="square"
          size={64}
          src={logo && `${MEDIA_FILE_URL}/${logo}`}
        />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (teamId) => (
      <a href={`/teams/edit/${teamId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit Team
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
  {
    title: 'Delete',
    dataIndex: 'key',
    render: (teamId) => <DeleteTeamButton teamId={teamId} />,
  },
];

export const TeamsTable = ({
  columnData,
  pagination,
  setPage,
  setPageSize,
}: {
  columnData: DataType[];
  pagination: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
}) => {
  return (
    <>
      <Table
        scroll={{ y: 1000 }}
        columns={columns}
        dataSource={[...columnData]}
        pagination={false}
      />
      <Pagination
        defaultCurrent={6}
        total={pagination}
        onChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};
