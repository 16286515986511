import { Button, Flex, Pagination, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Avatar, Space } from 'antd';

export type DataType = {
  key: string;
  index: number;
  date: string;
  team1: {
    logo: string;
    name: string;
  };
  team2: {
    logo: string;
    name: string;
  };
  map?: { name: string; photo: string };
};

const columns: ColumnsType<DataType> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    render: (date) => <p>{new Date(date).toLocaleString()}</p>,
  },
  {
    title: 'Team 1',
    dataIndex: 'team1',
    render: (team) => (
      <Space>
        <Avatar size={64} src={team.logo} shape="square" />
        <p>{team.name}</p>
      </Space>
    ),
  },
  {
    title: 'Team 2',
    dataIndex: 'team2',
    render: (team) => (
      <Space>
        <Avatar size={64} src={team.logo} shape="square" />
        <p>{team.name}</p>
      </Space>
    ),
  },
  {
    title: 'Map',
    dataIndex: 'map',
    render: (map) => (
      <Space>
        {map && <Avatar size={64} src={map.photo} shape="square" />}
        {map && <p>{map.name}</p>}
      </Space>
    ),
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (mapId) => (
      <a href={`/matches/edit/${mapId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit Match
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
];

export const MatchesTable = ({
  columnData,
  pagination,
  setPage,
  setPageSize,
}: {
  columnData: DataType[];
  pagination: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
}) => {
  return (
    <>
      <Table
        scroll={{ y: 1000 }}
        columns={columns}
        dataSource={[...columnData]}
        pagination={false}
      />
      <br />
      <Pagination
        defaultCurrent={6}
        total={pagination}
        onChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};
