import { Button, Form } from 'antd';
import { Header } from '@/layout/Header';
import { Select } from 'antd';
import { Avatar, Space } from 'antd';
import { Team } from '@/types/team';
import { FC } from 'react';
import { useGetUsersQuery } from '@/api/users';
import { Role } from '@/types/role.type';
import { User } from '@/types/user';
import { useAssignPlayerToTeamMutation } from '@/api/team-players';

type FieldType = {
  player: User['id'];
};

type TeamMembersSelectorProps = {
  teamId: Team['id'];
};

export const TeamPlayersSelector: FC<TeamMembersSelectorProps> = ({
  teamId,
}) => {
  const { data: playersData } = useGetUsersQuery({
    role: Role.PLAYER,
    skip: 0,
    take: 1000,
  });
  const [assignUserToTeam] = useAssignPlayerToTeamMutation();

  const onFinish = async ({ player }: FieldType) => {
    assignUserToTeam({ playerId: player, teamId });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const playersOptions = playersData?.users.map(
    ({ id, avatar, nickname, firstName, lastName }) => ({
      value: id,
      searchString: `${nickname} - ${firstName} ${lastName}`,
      label: (
        <Space key={id}>
          <Avatar
            shape="square"
            size={64}
            src={avatar}
            style={{ marginRight: 8 }}
          />
          {`${nickname} - ${firstName} ${lastName}`}
        </Space>
      ),
    }),
  );

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '60%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Players" />
        </Form.Item>

        <Form.Item
          label="Player"
          name="player"
          rules={[{ required: true, message: 'Please select first team' }]}
        >
          <Select
            showSearch
            placeholder="Select / Search player"
            style={{ height: 76 }}
            options={playersOptions}
            filterOption={filterOption}
          ></Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Assign player to team
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
