import { TeamSelector } from './TeamSelector';
import { TeamPlayersSelector } from './TeamPlayersSelector';
import { useState } from 'react';
import {
  useGetCoachesByTeamIdQuery,
  useGetPlayersByTeamIdQuery,
} from '@/api/team-players';
import { Role } from '@/types/role.type';
import { TeamPlayersTable } from './TeamPlayersTable';
import { TeamCoachSelector } from './TeamCoachSelector';
import { TeamCoachesTable } from './TeamCoachesTable';

export type DataType = {
  id: string;
  key: string;
  index: number;
  email: string;
  name: string;
  nickname: string;
  role: Role;
  avatar: string;
  birthday: string;
  // status: { status: 'ACTIVE' | 'INACTIVE'; id: string };
};

export const TeamMembers = () => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const { data: teamPlayersData } = useGetPlayersByTeamIdQuery(
    { teamId: selectedTeamId },
    { skip: !selectedTeamId },
  );

  const { data: teamCoachesData } = useGetCoachesByTeamIdQuery(
    { teamId: selectedTeamId },
    { skip: !selectedTeamId },
  );

  const teamPlayers = teamPlayersData?.teamPlayers?.length
    ? teamPlayersData?.teamPlayers
    : [];

  const playersColumnData = teamPlayers.map(
    (user, i): DataType => ({
      id: user.id,
      key: user.id,
      index: i + 1,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      nickname: user.nickname,
      role: user.role,
      birthday: user.birthday,
      // status: {
      //   status:
      //     (user?.teamPlayers[0]?.status as 'ACTIVE' | 'INACTIVE') ??
      //     (user?.teamCoaches[0]?.status as 'ACTIVE' | 'INACTIVE'),
      //   id: user.id,
      // },
      avatar: user.avatar,
    }),
  );

  const teamCoaches = teamCoachesData?.teamCoaches?.length
    ? teamCoachesData?.teamCoaches
    : [];

  console.log({ teamCoaches });

  const coachesColumnData = teamCoaches.map(
    (user, i): DataType => ({
      id: user.id,
      key: user.id,
      index: i + 1,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      nickname: user.nickname,
      role: user.role,
      birthday: user.birthday,
      // status: {
      //   status: user?.teamCoaches[0]?.status as 'ACTIVE' | 'INACTIVE',
      //   id: user.id,
      // },
      avatar: user.avatar,
    }),
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TeamSelector
          setSelectedTeamId={setSelectedTeamId}
          header="Team members"
        />
        {selectedTeamId && (
          <>
            <TeamPlayersSelector teamId={selectedTeamId} />{' '}
            <TeamCoachSelector teamId={selectedTeamId} />
          </>
        )}
      </div>
      <h1 style={{ fontSize: 18 }}>Coaches</h1>
      <TeamCoachesTable columnData={coachesColumnData} />
      <br />

      <h1 style={{ fontSize: 18 }}>Players</h1>
      <TeamPlayersTable columnData={playersColumnData} />
    </div>
  );
};
