import { Header } from '@/layout/Header';
import { useGetMapsQuery } from '@/api/maps';
import { DataTypeMaps, MapsTable } from './MapsTable';

export const Maps = () => {
  const { data: mapsData } = useGetMapsQuery();
  const maps = mapsData?.maps || [];

  const columnData = maps.map(
    ({ id, name, photo, createdAt, updatedAt }, i): DataTypeMaps => ({
      key: id,
      index: i + 1,
      name: name,
      photo: photo || '',
      createdAt,
      updatedAt,
    }),
  );

  return (
    <>
      <Header header="All maps" />
      <MapsTable columnData2={columnData} />
    </>
  );
};
