import { User } from '@/types/user';
import { api } from './api';
import { Team } from '@/types/team';

const baseUrl = '/team-players';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlayersByTeamId: builder.query<
      { teamPlayers: User[] },
      { teamId: Team['id'] | null }
    >({
      query: ({ teamId }) => `${baseUrl}/${teamId}`,
    }),
    getCoachesByTeamId: builder.query<
      { teamCoaches: User[] },
      { teamId: Team['id'] | null }
    >({
      query: ({ teamId }) => `team-coaches/${teamId}`,
    }),
    assignCoachToTeam: builder.mutation<
      void,
      { teamId: Team['id']; coachId: User['id'] }
    >({
      invalidatesTags: ['Team-Players'],
      query: ({ teamId, coachId }) => ({
        url: `team-coaches`,
        body: { teamId, coachId },
        method: 'POST',
      }),
    }),
    assignPlayerToTeam: builder.mutation<
      void,
      { teamId: Team['id']; playerId: User['id'] }
    >({
      invalidatesTags: ['Team-Players'],
      query: ({ teamId, playerId }) => ({
        url: `${baseUrl}`,
        body: { teamId, playerId },
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = usersApi.endpoints;
export const {
  useGetPlayersByTeamIdQuery,
  useAssignPlayerToTeamMutation,
  useAssignCoachToTeamMutation,
  useGetCoachesByTeamIdQuery,
} = usersApi;
