import { Header } from '@/layout/Header';
import { useGetTrophiesQuery } from '@/api/trophies';
import { DataType, TrophiesTable } from './TrophiesTable';

export const Trophies = () => {
  const { data: trophiesData } = useGetTrophiesQuery();
  const trophies = trophiesData?.trophies || [];

  const columnData = trophies.map(
    ({ id, name, createdAt, description, image, updatedAt }, i): DataType => ({
      key: id,
      index: i + 1,
      image,
      updatedAt,
      name: name ?? '',
      createdAt,
      description: description ?? '',
    }),
  );
  return (
    <>
      <Header header="All Trophies" />
      <TrophiesTable columnData4={columnData} />
    </>
  );
};
