import { Form } from 'antd';
import { Header } from '@/layout/Header';
import { Select } from 'antd';
import { useGetTeamsQuery } from '@/api/teams';
import { Avatar, Space } from 'antd';
import { Team } from '@/types/team';
import { FC } from 'react';
import { MEDIA_FILE_URL } from '@/CONSTS';

type TeamSelector = {
  header: string;
  setSelectedTeamId: (teamId: Team['id']) => void;
};

export const TeamSelector: FC<TeamSelector> = ({
  setSelectedTeamId,
  header,
}) => {
  const { data: teamsData } = useGetTeamsQuery({ skip: 0, take: 1000 });

  const teamsOptions = teamsData?.teams.map(({ id, name, logo }) => ({
    value: id,
    searchString: name,
    label: (
      <Space>
        <Space>
          <Avatar
            shape="square"
            size={64}
            src={logo && `${MEDIA_FILE_URL}/${logo}`}
            style={{ marginRight: 8 }}
          />
        </Space>
        {name}
      </Space>
    ),
  }));

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '40%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header={header} />
        </Form.Item>

        <Form.Item
          label="Select Team"
          name="team"
          rules={[{ required: true, message: 'Please select team' }]}
        >
          <Select
            showSearch
            placeholder="Select / Search team"
            style={{ height: 76 }}
            onChange={(teamId: Team['id']) => {
              setSelectedTeamId(teamId);
            }}
            options={teamsOptions}
            filterOption={filterOption}
          ></Select>
        </Form.Item>
      </Form>
    </div>
  );
};
