import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api/api';
import { credentialsReducer } from './slices/credentials/credentialsSlice';

export const store = configureStore({
  reducer: combineReducers({
    [api.reducerPath]: api.reducer,
    credentials: credentialsReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);
