import React from 'react';
import { Button, Form, Input, Avatar, Space, Flex } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { useGetTrophyByIdQuery, useEditTrophyMutation } from '@/api/trophies';
import { ImgPrefix } from '@/layout/Layout';

import { LeftOutlined } from '@ant-design/icons';

type FieldType = {
  id: string;
  index: number;
  name?: string;
  description?: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
};

type EditType = {
  name?: string;
  description?: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
};

export const EditTrophy: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [createTrophy] = useEditTrophyMutation();

  const trophyId = id ? id : '';

  const { data: trophiesData } = useGetTrophyByIdQuery(
    {
      trophyId: trophyId as string,
    },
    { skip: !trophyId },
  );
  const trophies = (trophiesData?.trophy || {}) as FieldType;

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name || '');
    formData.append('description', values.description || '');
    formData.append('trophy', values.image);
    await createTrophy({ trophyId: trophyId as string, formData });
    navigate('/trophies/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/trophies/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all trophies
            </div>
          </Button>
        </Flex>
      </a>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        fields={[
          { name: ['description'], value: trophies.description },
          { name: ['name'], value: trophies.name },
        ]}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Edit trophy" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: false, message: 'Please input trophy name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Description"
          name="description"
          initialValue={trophies.description}
          rules={[
            { required: false, message: 'Please input trophy description' },
          ]}
        >
          <Input />
        </Form.Item>

        {trophies.image && (
          <div style={{ marginLeft: '25%', marginBottom: '30px' }}>
            <p style={{ marginBottom: '10px' }}>Current trophy photo: </p>
            <Space>
              <Avatar
                shape="square"
                size={130}
                src={`${ImgPrefix}${trophies.image}`}
              />
            </Space>
          </div>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="image">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Edit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
