import React from 'react';
import { Button, Form, Input } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate } from 'react-router-dom';
import { useCreateMapMutation } from '@/api/maps';
import { ImageUploader } from '../ImageUploader/ImageUploader';

type FieldType = {
  id: string;
  name: string;
  photo?: string;
  createdAt: string;
  updatedAt: string;
};

export const AddMaps: React.FC = () => {
  const [createMap] = useCreateMapMutation();
  const navigate = useNavigate();

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('photo', values.photo || '');

    await createMap(formData);
    navigate('/maps/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Add map" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input map name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          name="photo"
          required={false}
        >
          <p style={{ fontSize: 12 }}>
            <span style={{ color: 'red' }}>*</span> &nbsp; Image required
          </p>
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
