import { useDeleteUserMutation } from '@/api/users';
import { Team } from '@/types/team';
import { User } from '@/types/user';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';

export const DeleteUserButton: FC<{ userId: User['id'] }> = ({ userId }) => {
  const [deleteUser] = useDeleteUserMutation();
  return (
    <p
      onClick={() => deleteUser({ userId })}
      style={{ color: 'red', cursor: 'pointer' }}
      title="Delete user"
    >
      <DeleteOutlined /> Delete
    </p>
  );
};
