import type { ColumnsType } from 'antd/es/table';
import { Avatar, Space, Table } from 'antd';
import { Button, Flex } from 'antd';
import { ImgPrefix } from '@/layout/Layout';
import { DeleteTrophyButton } from './DeleteTrophyButton';

export type DataType = {
  key: string;
  index: number;
  name?: string;
  description?: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
};

const columns: ColumnsType<DataType> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Trophy',
    dataIndex: 'image',
    width: 120,

    render: (image) => (
      <Space>
        <Avatar
          size={64}
          src={ImgPrefix && `${ImgPrefix}${image}`}
          shape="square"
        />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
    width: 190,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <p>{description}</p>,
    width: 500,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString()}</p>,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString()}</p>,
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (trophyId) => (
      <a href={`/trophies/edit/${trophyId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit Trophy
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
  {
    title: 'Delete',
    dataIndex: 'key',
    render: (trophyId) => <DeleteTrophyButton trophyId={trophyId} />,
    width: 190,
  },
];

export const TrophiesTable = ({ columnData4 }: { columnData4: DataType[] }) => {
  return (
    <Table
      scroll={{ y: 1000 }}
      columns={columns}
      dataSource={[...columnData4]}
      pagination={{ pageSize: 20 }}
    />
  );
};
