import React from 'react';
import { Button, Form, Input } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate } from 'react-router-dom';
import { useCreateTeamMutation } from '@/api/teams';
import { ImageUploader } from '../ImageUploader/ImageUploader';

type FieldType = {
  name: string;
  logo: string;
};

export const AddTeam: React.FC = () => {
  const [createTeam] = useCreateTeamMutation();
  const navigate = useNavigate();

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('logo', values.logo);
    formData.append('name', values.name);

    await createTeam(formData);
    navigate('/teams/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Create team" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input team name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="logo">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
