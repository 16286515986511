import { Avatar, Space, Table, Flex, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { ImgPrefix } from '@/layout/Layout';
import { DeleteMapButton } from './DeleteMap';

export type DataTypeMaps = {
  key: string;
  index: number;
  name: string;
  photo: string;
  createdAt: Date;
  updatedAt: Date;
};

const columns: ColumnsType<DataTypeMaps> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    width: 120,

    render: (photo) => (
      <Space>
        <Avatar size={64} src={`${ImgPrefix}${photo}`} shape="square" />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
    width: 190,
  },

  {
    title: 'Created at',
    dataIndex: 'createdAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString()}</p>,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: 190,
    render: (updatedAt) => <p>{new Date(updatedAt).toLocaleString()}</p>,
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (mapId) => (
      <a href={`/maps/edit/${mapId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit Map
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
  {
    title: 'Delete',
    dataIndex: 'key',
    render: (mapId) => <DeleteMapButton mapId={mapId} />,
    width: 190,
  },
];

export const MapsTable = ({ columnData2 }: { columnData2: DataTypeMaps[] }) => {
  return (
    <Table
      scroll={{ y: 1000 }}
      columns={columns}
      dataSource={[...columnData2]}
      pagination={{ pageSize: 20 }}
    />
  );
};
