import { TrophyTeamsSelector } from './TrophyTeamSelector';
import { useState } from 'react';
import { useGetTeamsByTrophyQuery } from '@/api/trophies';
import { TrophyTeamDataType, TrophyTeamsTable } from './TrophyTeamsTable';
import { TrophySelector } from './TrophySelector';

export const TrophyTeam = () => {
  const [selectedTrophyId, setSelectedTrophyId] = useState<string | null>(null);

  const { data: teamsData } = useGetTeamsByTrophyQuery(
    { trophyId: selectedTrophyId! },
    { skip: !selectedTrophyId },
  );

  const columnData = teamsData?.teams.map(
    (team, i): TrophyTeamDataType => ({
      key: team.id,
      name: team.name,
      logo: team.logo,
      index: i + 1,
    }),
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TrophySelector
          setSelectedTrophyId={setSelectedTrophyId}
          header="Team Trophy"
        />
        {selectedTrophyId && (
          <TrophyTeamsSelector trophyId={selectedTrophyId} />
        )}
      </div>
      <TrophyTeamsTable columnData={columnData ?? []} />
    </div>
  );
};
