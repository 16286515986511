import { Map } from '@/types/maps';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useDeleteMapMutation } from '@/api/maps';

export const DeleteMapButton: FC<{ mapId: Map['id'] }> = ({ mapId }) => {
  const [deleteMap] = useDeleteMapMutation();
  return (
    <p
      onClick={() => deleteMap({ mapId })}
      style={{ color: 'red', cursor: 'pointer' }}
      title="Delete map"
    >
      <DeleteOutlined /> Delete
    </p>
  );
};
