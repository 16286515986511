import { Header } from '@/layout/Header';
import { useGetOffersQuery } from '@/api/offers';
import { OfferDataType, OffersTable } from './OffersTable';

export const Offers = () => {
  const { data: offersData } = useGetOffersQuery();

  const columnData = offersData?.offers.map(
    (offer, i): OfferDataType => ({
      id: offer.id,
      key: offer.id,
      index: i + 1,
      imageSmall: offer.imageSmall || '',
      imageLarge: offer.imageLarge || '',
      active: offer.active,
      order: offer.order || 0,
      updatedAt: offer.updatedAt,
      name: offer.name || '',
      url: offer.url || '',
      page: offer.page || '',
      createdAt: offer.createdAt,
      description: offer.description || '',
    }),
  );

  return (
    <>
      <Header header="All Offers" />
      <OffersTable columnData2={columnData ?? []} />
    </>
  );
};
