import { useGetUsersQuery } from '@/api/users';
import { Role } from '@/types/role.type';
import { Header } from '@/layout/Header';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { DataType, PlayersTable } from './PlayersTable';
import { useState } from 'react';

export const Players = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: usersData } = useGetUsersQuery({
    role: Role.PLAYER,
    skip: (page - 1) * pageSize,
    take: pageSize,
  });

  const users = usersData?.users ?? [];
  const columnData = users.map(
    (user, i): DataType => ({
      id: user.id,
      key: user.id,
      index: i + 1,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      nickname: user.nickname,
      role: user.role,
      birthday: user.birthday,
      // status: {
      //   status: user.teamPlayers[0].status as 'ACTIVE' | 'INACTIVE',
      //   id: user.id,
      // },
      avatar: user.avatar && `${MEDIA_FILE_URL}/${user.avatar}`,
    }),
  );

  return (
    <>
      <Header header="Players" />{' '}
      <PlayersTable
        columnData={columnData}
        setPage={setPage}
        setPageSize={setPageSize}
        pagination={usersData?.count ?? 0}
      />
    </>
  );
};
