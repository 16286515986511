import { User } from '@/types/user';
import { api } from './api';

const baseUrl = '/user-assignments';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    toggleUserStatus: builder.mutation<void, { userId: User['id'] }>({
      query: ({ userId }) => ({
        url: `${baseUrl}/${userId}/status`,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = usersApi.endpoints;
export const { useToggleUserStatusMutation } = usersApi;
