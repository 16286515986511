import { api } from './api';
import { Team } from '@/types/team';
import { Pagination } from './matches';

const baseUrl = '/teams';

const teamsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<{ teams: Team[]; count: number }, Pagination>({
      query: ({ skip, take }) => `${baseUrl}/all?skip=${skip}&take=${take}`,
      providesTags: ['Team'],
    }),
    createTeam: builder.mutation<{ user: Team }, FormData>({
      invalidatesTags: ['Team'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    uploadLogo: builder.mutation<void, { file: File; teamId: Team['id'] }>({
      invalidatesTags: ['Team'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    deleteTeam: builder.mutation<{ team: Team }, { teamId: Team['id'] }>({
      invalidatesTags: ['Team'],
      query: ({ teamId }) => ({
        url: `${baseUrl}/${teamId}`,
        method: 'DELETE',
      }),
    }),
    editTeam: builder.mutation<
      { team: Team },
      { formData: FormData; teamId: Team['id'] }
    >({
      invalidatesTags: ['Team'],
      query: ({ teamId, formData }) => ({
        url: `${baseUrl}/${teamId}`,
        method: 'PATCH',
        body: formData,
      }),
    }),
    getTeamById: builder.query<{ team: Team }, { teamId: Team['id'] }>({
      query: ({ teamId }) => `${baseUrl}/${teamId}`,
      providesTags: ['Trophies'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = teamsApi.endpoints;
export const {
  useGetTeamsQuery,
  useCreateTeamMutation,
  useUploadLogoMutation,
  useDeleteTeamMutation,
  useEditTeamMutation,
  useGetTeamByIdQuery,
} = teamsApi;
