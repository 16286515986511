import { MEDIA_FILE_URL } from '@/CONSTS';
import { useToggleActiveMutation } from '@/api/offers';
import { Offer } from '@/types/offers';
import { Avatar, Space, Table, Flex, Button, Checkbox } from 'antd';
import type { ColumnsType } from 'antd/es/table';

export type BannerDataType = {
  id: string;
  key: string;
  index: number;
  active: boolean;
  description: string;
  name: string;
  url: string;
  imageSmall: string;
  imageLarge: string;
  createdAt: Date;
  updatedAt: Date;
};

const columns = (
  useToggleActiveMutation: ({ offerId }: { offerId: Offer['id'] }) => void,
): ColumnsType<BannerDataType> => [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Image Small',
    dataIndex: 'imageSmall',
    width: 120,

    render: (imageSmall) => (
      <Space>
        <Avatar
          size={64}
          src={`${MEDIA_FILE_URL}/${imageSmall}`}
          shape="square"
        />
      </Space>
    ),
  },
  {
    title: 'Image Large',
    dataIndex: 'imageLarge',
    width: 120,

    render: (imageLarge) => (
      <Space>
        <Avatar
          size={64}
          src={`${MEDIA_FILE_URL}/${imageLarge}`}
          shape="square"
        />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
    width: 190,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <p>{description}</p>,
    width: 500,
  },
  {
    title: 'Url',
    dataIndex: 'url',
    width: 400,
    render: (url) => <p>{url}</p>,
  },
  {
    title: 'Is Active',
    dataIndex: 'active',
    width: 100,
    render: (_, record) => {
      return (
        <Checkbox
          defaultChecked={record.active}
          onChange={() => {
            useToggleActiveMutation({ offerId: record.key });
          }}
        />
      );
    },
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (bannerId) => (
      <a href={`/banners/edit/${bannerId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit Banner
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString('pl')}</p>,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString('pl')}</p>,
  },
];

export const BannersTable = ({
  columnData,
}: {
  columnData: BannerDataType[];
}) => {
  const [toggleActiveMutation] = useToggleActiveMutation();

  return (
    <Table
      scroll={{ y: 1000 }}
      columns={columns(toggleActiveMutation)}
      dataSource={[...columnData]}
      pagination={{ pageSize: 20 }}
    />
  );
};
