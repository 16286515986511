import { Avatar, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Role } from '@/types/role.type';
import { MEDIA_FILE_URL } from '@/CONSTS';

export type TrophyUserDataType = {
  id: string;
  key: string;
  index: number;
  email: string;
  name: string;
  nickname: string;
  role: Role;
  avatar: string;
  birthday: string;
};

const columns: ColumnsType<TrophyUserDataType> = [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    width: 120,

    render: (avatar) => (
      <Space>
        <Avatar size={64} src={`${MEDIA_FILE_URL}/${avatar}`} shape="square" />
      </Space>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    render: (role) => <p>{role}</p>,
  },
];

export const TrophyPlayersTable = ({
  columnData,
}: {
  columnData: TrophyUserDataType[];
}) => {
  return (
    <>
      <Table
        scroll={{ x: 1500, y: 1000 }}
        columns={columns}
        dataSource={[...columnData]}
        pagination={false}
      />
    </>
  );
};
