import { Header } from '@/layout/Header';
import { useGetBannersQuery } from '@/api/banner';
import { BannerDataType, BannersTable } from './BannersTable';

export const Banners = () => {
  const { data: bannersData } = useGetBannersQuery();
  const banners = bannersData?.banners || [];

  const columnData = banners.map(
    (banner, i): BannerDataType => ({
      id: banner.id,
      key: banner.id,
      index: i + 1,
      imageSmall: banner.imageSmall || '',
      imageLarge: banner.imageLarge || '',
      active: banner.active,
      updatedAt: banner.updatedAt,
      name: banner.name || '',
      url: banner.url || '',
      createdAt: banner.createdAt,
      description: banner.description || '',
    }),
  );

  return (
    <>
      <Header header="All Banners" />
      <BannersTable columnData={columnData} />
    </>
  );
};
