import { Button, Form } from 'antd';
import { Header } from '@/layout/Header';
import { Select } from 'antd';
import { Avatar, Space } from 'antd';
import { Team } from '@/types/team';
import { FC } from 'react';
import { useGetUsersQuery } from '@/api/users';
import { Role } from '@/types/role.type';
import { User } from '@/types/user';
import { useAssignCoachToTeamMutation } from '@/api/team-players';
import { MEDIA_FILE_URL } from '@/CONSTS';
const { Option } = Select;

type FieldType = {
  coach: User['id'];
};

type TeamMembersSelectorProps = {
  teamId: Team['id'];
};

export const TeamCoachSelector: FC<TeamMembersSelectorProps> = ({ teamId }) => {
  const { data: coachesData } = useGetUsersQuery({
    role: Role.COACH,
    skip: 0,
    take: 1000,
  });
  const [assignUserToTeam] = useAssignCoachToTeamMutation();

  console.log(coachesData?.users);

  const onFinish = async ({ coach }: FieldType) => {
    assignUserToTeam({ coachId: coach, teamId });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const coachesOptions = coachesData?.users.map(
    ({ id, avatar, nickname, firstName, lastName }) => ({
      value: id,
      searchString: `${nickname} - ${firstName} ${lastName}`,
      label: (
        <Space>
          <Space>
            <Avatar
              shape="square"
              size={64}
              src={`${MEDIA_FILE_URL}/${avatar}`}
              style={{ marginRight: 8 }}
            />
          </Space>
          {`${nickname} - ${firstName} ${lastName}`}
        </Space>
      ),
    }),
  );

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '60%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Coaches" />
        </Form.Item>

        <Form.Item
          label="Coach"
          name="coach"
          rules={[{ required: true, message: 'Please select first team' }]}
        >
          <Select
            placeholder="Select / Search coach"
            style={{ height: 76 }}
            showSearch
            options={coachesOptions}
            filterOption={filterOption}
          ></Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Assign coach to team
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
