import React from 'react';
import { ImgPrefix } from '@/layout/Layout';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Avatar,
  Space,
  Flex,
} from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { useGetMapByIdQuery, useEditMapMutation } from '@/api/maps';
import { UserSwitchOutlined, LeftOutlined } from '@ant-design/icons';

type FieldType = {
  id: string;
  index: number;
  name: string;
  photo?: string;
  createdAt: Date;
  updatedAt: Date;
};

export const EditMap: React.FC = () => {
  let { id } = useParams();
  const mapId = !!id ? id : '';

  const { data: mapsData } = useGetMapByIdQuery({ mapId });
  const maps = (mapsData?.map || {}) as FieldType;

  const navigate = useNavigate();

  const [updateMap] = useEditMapMutation();
  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('photo', values.photo || '');
    await updateMap({ mapId, formData });
    navigate('/maps/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/maps/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all maps
            </div>
          </Button>
        </Flex>
      </a>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        fields={[{ name: ['name'], value: maps.name }]}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Edit map" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input map name' }]}
        >
          <Input />
        </Form.Item>

        {maps.photo && (
          <div style={{ marginLeft: '25%', marginBottom: '30px' }}>
            <p style={{ marginBottom: '10px' }}>Current map photo: </p>
            <Space>
              <Avatar
                shape="square"
                size={130}
                src={`${ImgPrefix}${maps.photo}`}
              />
            </Space>
          </div>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="photo">
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Edit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
