import { Button, Form } from 'antd';
import { Header } from '../../layout/Header';
import { Select } from 'antd';
import { Avatar, Space } from 'antd';
import { FC } from 'react';
import { useGetUsersQuery } from '../../api/users';
import { Trophy } from '../../types/trophies';
import { useTogglePlayerTrophyMutation } from '@/api/trophies';
import { Role } from '@/types/role.type';
import { ImgPrefix } from '@/layout/Layout';
import { MEDIA_FILE_URL } from '@/CONSTS';

type FieldType = {
  player: Trophy['id'];
};

type TrophiesMembersSelectorProps = {
  trophyId: Trophy['id'];
};

export const TrophyPlayersSelector: FC<TrophiesMembersSelectorProps> = ({
  trophyId,
}) => {
  const { data: playersData } = useGetUsersQuery({
    role: Role.PLAYER,
    skip: 0,
    take: 1000,
  });
  const [assignUserToTeam] = useTogglePlayerTrophyMutation();

  const onFinish = async ({ player }: FieldType) => {
    assignUserToTeam({ playerId: player, trophyId });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const playersOptions = playersData?.users.map(
    ({ id, avatar, nickname, firstName, lastName }) => ({
      value: id,
      searchString: `${nickname} - ${firstName} ${lastName}`,
      label: (
        <Space>
          <Space>
            <Avatar
              shape="square"
              size={64}
              src={`${MEDIA_FILE_URL}/${avatar}`}
              style={{ marginRight: 8 }}
            />
          </Space>
          {`${nickname} - ${firstName} ${lastName}`}
        </Space>
      ),
    }),
  );

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '60%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Players" />
        </Form.Item>

        <Form.Item
          label="Player"
          name="player"
          rules={[{ required: true, message: 'Please select first team' }]}
        >
          <Select
            placeholder="Select player"
            style={{ height: 76 }}
            showSearch
            filterOption={filterOption}
            options={playersOptions}
          ></Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Assign trophy to player
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
