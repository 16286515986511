import { api } from './api';

const s3DemoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadDemoToS3: builder.mutation<void, { body: File; signedUrl: string }>({
      query: ({ signedUrl, body }) => ({ url: signedUrl, body, method: 'PUT' }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = s3DemoApi.endpoints;
export const { useUploadDemoToS3Mutation } = s3DemoApi;
