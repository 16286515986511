// import { Match } from '@/types/match';
import { api } from './api';
import { Offer } from '@/types/offers';

const baseUrl = '/offers';

const offersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<{ offers: Offer[] }, void>({
      query: () => baseUrl,
    }),
    createOffers: builder.mutation<{ offer: Offer }, FormData>({
      invalidatesTags: ['Offers'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    deleteOffer: builder.mutation<{ offer: Offer }, { offerId: Offer['id'] }>({
      invalidatesTags: ['Offers'],
      query: ({ offerId }) => ({
        url: `${baseUrl}/${offerId}`,
        method: 'DELETE',
      }),
    }),
    editOffer: builder.mutation<
      { trophies: Offer },
      { formData: FormData; offerId: Offer['id'] }
    >({
      invalidatesTags: ['Offers'],
      query: ({ offerId, formData }) => ({
        url: `${baseUrl}/${offerId}`,
        method: 'PATCH',
        body: formData,
      }),
    }),
    getOfferById: builder.query<{ offer: Offer }, { offerId: Offer['id'] }>({
      query: ({ offerId }) => `${baseUrl}/${offerId}`,
      providesTags: ['Offers'],
    }),
    toggleActive: builder.mutation<void, { offerId: Offer['id'] }>({
      query: ({ offerId }) => ({
        url: `${baseUrl}/${offerId}/toggle-active`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Offers'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = offersApi.endpoints;
export const {
  useGetOffersQuery,
  useCreateOffersMutation,
  useEditOfferMutation,
  useToggleActiveMutation,
  useDeleteOfferMutation,
  useGetOfferByIdQuery,
} = offersApi;
