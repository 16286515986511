import { Avatar, Space, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Role } from '@/types/role.type';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { useToggleUserStatusMutation } from '@/api/user-assignemtn';
import { User } from '@/types/user';

export type DataType = {
  id: string;
  key: string;
  index: number;
  email: string;
  name: string;
  nickname: string;
  role: Role;
  avatar: string;
  birthday: string;
  // status: { status: 'ACTIVE' | 'INACTIVE'; id: string };
};

const columns =
  (): // toggleUserStatusMutation: ({ userId }: { userId: User['id'] }) => void,
  ColumnsType<DataType> => [
    {
      title: 'Index',
      dataIndex: 'index',
      render: (text) => <p>{text}</p>,
      width: 80,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      width: 120,

      render: (avatar) => (
        <Space>
          <Avatar size={64} src={`${MEDIA_FILE_URL}/${avatar}`} />
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (role) => <p>{role}</p>,
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
      render: (birthday) => (
        <p>{birthday ? new Date(birthday).toDateString() : '-----'}</p>
      ),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: ({ status }) => <p>{status}</p>,
    // },
    // {
    //   title: 'Make active',
    //   dataIndex: 'status',
    //   render: ({ id, status }) => {
    //     return (
    // <Switch
    //   defaultChecked={status === 'ACTIVE'}
    //   onChange={() => {
    //     toggleUserStatusMutation({ userId: id });
    //   }}
    // />
    //     );
    //   },
    // },
  ];

export const TeamCoachesTable = ({
  columnData,
}: {
  columnData: DataType[];
}) => {
  // const [toggleUserStatusMutation] = useToggleUserStatusMutation();
  return (
    <Table
      scroll={{ x: 1500, y: 1000 }}
      columns={columns()}
      dataSource={[...columnData]}
      pagination={{ pageSize: 20 }}
    />
  );
};
