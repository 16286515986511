import { useDeleteTeamMutation } from '@/api/teams';
import { Team } from '@/types/team';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';

export const DeleteTeamButton: FC<{ teamId: Team['id'] }> = ({ teamId }) => {
  const [deleteTeam] = useDeleteTeamMutation();
  return (
    <p
      onClick={() => deleteTeam({ teamId })}
      style={{ color: 'red', cursor: 'pointer' }}
      title="Delete team"
    >
      <DeleteOutlined /> Delete
    </p>
  );
};
