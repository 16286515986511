import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { ConfigProvider } from 'antd';
import { App } from './App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={
          !['production', 'staging'].includes(import.meta.env.NODE_ENV)
            ? {
                token: {
                  colorPrimary: 'green',
                },
              }
            : undefined
        }
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);
