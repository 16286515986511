import { Match } from '@/types/match';
import { api } from './api';

type DemoUploadProps = {
  matchId: Match['id'];
  fileName: string;
};

const baseUrl = '/demo-file';

const demoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDemoUploadSignedUrl: builder.mutation<
      { signedUrl: string },
      DemoUploadProps
    >({
      query: (body) => ({ url: `${baseUrl}/upload/url`, body, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = demoApi.endpoints;
export const { useGetDemoUploadSignedUrlMutation } = demoApi;
