import { useGetUsersQuery } from '@/api/users';
import { UserDataType, UsersTable } from './UsersTable';
import { Header } from '@/layout/Header';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { useState } from 'react';

export const Users = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: usersData } = useGetUsersQuery({
    skip: (page - 1) * pageSize,
    take: pageSize,
  });

  const users = usersData?.users ?? [];
  const columnData = users.map(
    (user, i): UserDataType => ({
      id: user.id,
      key: user.id,
      index: i + 1,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      nickname: user.nickname,
      role: user.role,
      birthday: user.birthday,
      avatar: user.avatar && `${MEDIA_FILE_URL}/${user.avatar}`,
      steamId: user.steamId,
    }),
  );
  return (
    <>
      <Header header="Users" />
      <UsersTable
        setPage={setPage}
        setPageSize={setPageSize}
        columnData={columnData}
        pagination={usersData?.count ?? 0}
      />
    </>
  );
};
