import React from 'react';
import {
  Button,
  Form,
  Avatar,
  Space,
  Flex,
  Select,
  TimePicker,
  DatePicker,
} from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useGetMatchByIdQuery, useUpdateMatchMutation } from '@/api/matches';
import { useGetMapsQuery } from '@/api/maps';
import { MEDIA_FILE_URL } from '@/CONSTS';

type MatchFieldType = {
  date: string;
  time: string;
  mapId: string;
};

export const EditMatch: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const matchId = !!id ? id : '';

  const { data: matchData } = useGetMatchByIdQuery({ matchId });
  const { data: matchesData } = useGetMapsQuery();
  const [updateMatch] = useUpdateMatchMutation();

  const onFinish = async (values: MatchFieldType) => {
    const matchDate = new Date(values.date);
    const time = new Date(values.time);
    matchDate.setHours(time.getHours(), time.getMinutes(), 0, 0);

    await updateMatch({ matchId, ...values, date: matchDate });
    navigate('/matches/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <a href={`/matches/all`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <LeftOutlined />
              Back to all matches
            </div>
          </Button>
        </Flex>
      </a>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Edit map" />
        </Form.Item>

        {matchData?.match && (
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <p>
              Current Date: &nbsp;
              {new Date(matchData.match.date)
                .toLocaleString('pl')
                .split(', ')[0]
                .split('.')
                .join('-')}
            </p>

            <p>
              Current Time: &nbsp;
              {new Date(matchData.match.date).toLocaleString().split(', ')[1]}
            </p>

            <p>Current map: </p>
            <br />
            <Space>
              <Avatar
                shape="square"
                size={64}
                src={`${MEDIA_FILE_URL}/${matchData?.match.map.photo}`}
                style={{ marginRight: 8 }}
              />
              <p>{matchData?.match.map.name}</p>
            </Space>
          </Form.Item>
        )}

        <Form.Item<MatchFieldType>
          label="Date"
          name="date"
          rules={[{ required: false, message: 'Please input match date' }]}
        >
          <DatePicker format={'DD-MM-YYYY'} />
        </Form.Item>

        <Form.Item<MatchFieldType>
          label="Time"
          name="time"
          rules={[{ required: false, message: 'Please input match time' }]}
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          label="Map"
          name="mapId"
          rules={[{ required: false, message: 'Please select map' }]}
        >
          <Select
            placeholder="Select map"
            style={{ height: 76 }}
            options={matchesData?.maps.map(({ id, name, photo }) => {
              return {
                value: id,
                label: (
                  <Space>
                    <Space>
                      <Avatar
                        shape="square"
                        size={64}
                        src={`${MEDIA_FILE_URL}/${photo}`}
                        style={{ marginRight: 8 }}
                      />
                    </Space>
                    {name}
                  </Space>
                ),
              };
            })}
          ></Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Edit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
