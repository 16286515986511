import { User } from '@/types/user';
import { api } from './api';
import { Role } from '@/types/role.type';
import { Pagination } from './matches';

const baseUrl = '/users';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<{ user: User }, void>({
      providesTags: (result) =>
        result ? [{ id: result.user.id, type: 'User' as const }] : [],
      query: () => `${baseUrl}/current`,
    }),
    getUserById: builder.query<{ user: User }, { userId: User['id'] }>({
      providesTags: (result) =>
        result ? [{ id: result.user.id, type: 'User' as const }] : [],
      query: ({ userId }) => `${baseUrl}/${userId}`,
    }),
    getUsers: builder.query<
      { users: User[]; count: number },
      Pagination & { role?: Role }
    >({
      query: ({ skip, take, role }) => {
        const url = `${baseUrl}?skip=${skip}&take=${take}`;
        return role ? `${url}&role=${role}` : `${url}`;
      },
    }),
    createUser: builder.mutation<{ user: User }, FormData>({
      invalidatesTags: ['User'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    updateUser: builder.mutation<
      { user: User },
      { body: FormData; userId: User['id'] }
    >({
      invalidatesTags: ['User'],
      query: ({ userId, body }) => ({
        url: `${baseUrl}/${userId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteUser: builder.mutation<{ user: User }, { userId: User['id'] }>({
      invalidatesTags: ['User'],
      query: ({ userId }) => ({
        url: `${baseUrl}/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = usersApi.endpoints;
export const {
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} = usersApi;
