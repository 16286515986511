import React from 'react';
import { Button, Form, Input } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate } from 'react-router-dom';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { useCreateTrophyMutation } from '@/api/trophies';

type FieldType = {
  id: string;
  index: number;
  name?: string;
  description?: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
};

export const AddTrophy: React.FC = () => {
  const [createTrophie] = useCreateTrophyMutation();
  const navigate = useNavigate();

  const onFinish = async (values: FieldType) => {
    const formData = new FormData();
    formData.append('name', values.name || '');
    formData.append('description', values.description || '');
    formData.append('trophy', values.image);

    await createTrophie(formData);
    navigate('/trophies/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Add trophy" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: false, message: 'Please input trophy name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Description"
          name="description"
          rules={[
            { required: false, message: 'Please input trophy description' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          name="image"
          rules={[{ required: true, message: 'Please input trophy image' }]}
        >
          <ImageUploader currentFileImgUrl={''} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
