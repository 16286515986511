import { Button, Form } from 'antd';
import { Header } from '@/layout/Header';
import { Select } from 'antd';
import { Avatar, Space } from 'antd';
import { FC } from 'react';
import { Trophy } from '@/types/trophies';
import { useGetTeamsQuery } from '@/api/teams';
import { useToggleTeamTrophyMutation } from '@/api/trophies';
const { Option } = Select;
import { ImgPrefix } from '@/layout/Layout';
import { MEDIA_FILE_URL } from '@/CONSTS';

type FieldType = {
  team: Trophy['id'];
};

type TrophiesMembersSelectorProps = {
  trophyId: Trophy['id'];
};

export const TrophyTeamsSelector: FC<TrophiesMembersSelectorProps> = ({
  trophyId,
}) => {
  const { data: teamsData } = useGetTeamsQuery({ skip: 0, take: 1000 });
  const [assignUserToTeam] = useToggleTeamTrophyMutation();

  const onFinish = async ({ team }: FieldType) => {
    assignUserToTeam({ teamId: team, trophyId });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const teamsOptions = teamsData?.teams.map(({ id, name, logo }) => ({
    value: id,
    searchString: name,
    label: (
      <Space>
        <Space>
          <Avatar
            shape="square"
            size={64}
            src={logo && `${MEDIA_FILE_URL}/${logo}`}
            style={{ marginRight: 8 }}
          />
        </Space>
        {name}
      </Space>
    ),
  }));

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div style={{ width: '60%' }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Team" />
        </Form.Item>

        <Form.Item
          label="Team"
          name="team"
          rules={[{ required: true, message: 'Please select first team' }]}
        >
          <Select
            placeholder="Select team"
            style={{ height: 76 }}
            showSearch
            options={teamsOptions}
            filterOption={filterOption}
          ></Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Assign trophy to team
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
