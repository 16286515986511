import {
  UserOutlined,
  BarChartOutlined,
  TeamOutlined,
  PlusOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu, MenuProps } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const menuItems: MenuProps['items'] = [
  {
    key: '/users',
    icon: <UserOutlined />,
    label: 'Users',
    children: [
      {
        key: '/users/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="users/all">All users</Link>,
      },
      {
        key: '/users/players',
        icon: <UserOutlined />,
        label: <Link to="users/players">Players</Link>,
      },
      {
        key: '/users/coaches',
        icon: <UserOutlined />,
        label: <Link to="users/coaches">Coaches</Link>,
      },
      {
        key: '/users/create',
        icon: <PlusOutlined />,
        label: <Link to="users/create">Create user</Link>,
      },
    ],
  },
  {
    key: '/teams',
    icon: <TeamOutlined />,
    label: 'Teams',
    children: [
      {
        key: '/teams/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="teams/all">All teams</Link>,
      },
      {
        key: '/teams/members',
        icon: <UserSwitchOutlined />,
        label: <Link to="teams/members">Team members</Link>,
      },
      {
        key: '/teams/create',
        icon: <PlusOutlined />,
        label: <Link to="teams/create">Create team</Link>,
      },
    ],
  },
  {
    key: '/trophies',
    icon: <BarChartOutlined />,
    label: 'Trophies',
    children: [
      {
        key: '/trophies/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="/trophies/all">All trophies</Link>,
      },
      {
        key: '/trophies/create',
        icon: <PlusOutlined />,
        label: <Link to="/trophies/create">Create trophy</Link>,
      },
      {
        key: '/offers/trophy-players',
        icon: <PlusOutlined />,
        label: <Link to="/trophies/trophy-players">Player trophies</Link>,
      },
      {
        key: '/offers/trophy-teams',
        icon: <PlusOutlined />,
        label: <Link to="/trophies/trophy-teams">Team trophies</Link>,
      },
    ],
  },
  {
    key: '/matches',
    icon: <BarChartOutlined />,
    label: 'Matches',
    children: [
      {
        key: '/matches/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="matches/all">All matches</Link>,
      },
      {
        key: '/matches/create',
        icon: <PlusOutlined />,
        label: <Link to="matches/create">Create match</Link>,
      },
    ],
  },
  // {
  //   key: '/events',
  //   icon: <BarChartOutlined />,
  //   label: 'Events',
  //   children: [
  //     {
  //       key: '/events/all',
  //       icon: <UserSwitchOutlined />,
  //       label: <Link to="events/all">All events</Link>,
  //     },
  //     {
  //       key: '/events/create',
  //       icon: <PlusOutlined />,
  //       label: <Link to="events/create">Create event</Link>,
  //     },
  //   ],
  // },
  {
    key: '/maps',
    icon: <BarChartOutlined />,
    label: 'Maps',
    children: [
      {
        key: '/maps/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="/maps/all">Maps</Link>,
      },
      {
        key: '/maps/create',
        icon: <UserSwitchOutlined />,
        label: <Link to="/maps/create">Create maps</Link>,
      },
    ],
  },
  {
    key: '/demo',
    icon: <BarChartOutlined />,
    label: 'Demo',
    children: [
      {
        key: '/demo/upload',
        icon: <UserSwitchOutlined />,
        label: <Link to="demo/upload">Upload demo</Link>,
      },
    ],
  },

  {
    key: '/offers',
    icon: <BarChartOutlined />,
    label: 'Offers',
    children: [
      {
        key: '/offers/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="/offers/all">All offers</Link>,
      },
      {
        key: '/offers/create',
        icon: <PlusOutlined />,
        label: <Link to="/offers/create">Create offer</Link>,
      },
    ],
  },
  {
    key: '/banners',
    icon: <BarChartOutlined />,
    label: 'Banner',
    children: [
      {
        key: '/banners/all',
        icon: <UserSwitchOutlined />,
        label: <Link to="/banners/all">Banner</Link>,
      },
      {
        key: '/banners/create',
        icon: <UserSwitchOutlined />,
        label: <Link to="/banners/create">Upload banner</Link>,
      },
    ],
  },
];

export const Menu = () => {
  const location = window.location.pathname;
  useEffect(() => {}, [location]);
  return (
    <AntdMenu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location]}
      items={menuItems}
    />
  );
};
