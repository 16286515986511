import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC } from 'react';

const MAX_FILE_SIZE = 500 * 1024 * 1024;

type UploaderProps = {
  onChange?: (...args: any) => void;
  loading?: boolean;
};

export const DemoUploader: FC<UploaderProps> = ({ onChange, loading }) => {
  const beforeUpload = (file: RcFile) => {
    const filenameSpits = file.name.split('.');
    const ext = '.' + filenameSpits[filenameSpits.length - 1];
    const isDemFile = ext === '.dem';

    if (!isDemFile) {
      message.error('You can only upload .dem file!');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      notification.error({
        description: `Max size is 500MB`,
        message: 'File is to large',
      });

      return false;
    }

    // @ts-ignore
    onChange?.(file);
    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{loading ? 'Uploading' : 'Upload'}</div>
    </div>
  );

  return (
    <div>
      <div>
        <Upload
          beforeUpload={beforeUpload}
          className="avatar-uploader"
          listType="picture-card"
          name="demo"
          showUploadList={false}
          onChange={onChange}
        >
          {uploadButton}
        </Upload>
      </div>
      <p>Info: max file size is 500MB</p>
    </div>
  );
};
