import { Outlet } from 'react-router-dom';
import { Layout as AntdLayout, theme } from 'antd';
import { Footer } from './Footer';
import { Menu } from './Menu';
export const ImgPrefix =
  'https://ple-media-files-staging.s3.eu-central-1.amazonaws.com/';
const { Sider, Content } = AntdLayout;
export const Layout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AntdLayout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu />
      </Sider>
      <AntdLayout
        className="site-layout"
        style={{
          marginLeft: 200,
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Content style={{ overflow: 'initial' }}>
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer />
      </AntdLayout>
    </AntdLayout>
  );
};
