import type { ColumnsType } from 'antd/es/table';
import { Avatar, Space, Table } from 'antd';
import { MEDIA_FILE_URL } from '@/CONSTS';

export type TrophyTeamDataType = {
  key: string;
  index: number;
  name: string;
  logo: string;
};

const columns: ColumnsType<TrophyTeamDataType> = [
  {
    title: 'Avatar',
    dataIndex: 'logo',
    width: 120,
    render: (logo) => (
      <Space>
        <Avatar size={64} src={`${MEDIA_FILE_URL}/${logo}`} shape="square" />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
  },
];

export const TrophyTeamsTable = ({
  columnData,
}: {
  columnData: TrophyTeamDataType[];
}) => {
  return (
    <Table
      scroll={{ y: 1000 }}
      columns={columns}
      dataSource={[...columnData]}
      pagination={{ pageSize: 20 }}
    />
  );
};
