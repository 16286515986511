import { ReactElement } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const navigate = useNavigate();

  const { doesSessionExist, loading } = useSessionContext() as {
    doesSessionExist: boolean;
    loading: boolean;
  };

  if (!loading && !doesSessionExist) {
    navigate('/auth');
    return <></>;
  }

  return children;
};
