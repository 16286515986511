import { theme } from 'antd';
import { Header as AntdHeader } from 'antd/es/layout/layout';

export const Header = ({ header }: { header: string }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AntdHeader
      style={{ padding: 0, background: colorBgContainer, fontSize: 28 }}
    >
      {header}
    </AntdHeader>
  );
};
