import { Avatar, Space, Table, Flex, Button, Checkbox } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useToggleActiveMutation } from '@/api/offers';
import { Offer } from '@/types/offers';
import { ImgPrefix } from '@/layout/Layout';
import { OfferPage } from './offer-page.type';
import { pageMapping } from './AddOffer';

export type OfferDataType = {
  key: string;
  id: string;
  index: number;
  imageLarge: string;
  active: boolean;
  page: OfferPage;
  order: number;
  imageSmall: string;
  updatedAt: Date;
  name: string;
  url: string;
  createdAt: Date;
  description: string;
};

const columns = (
  useToggleActiveMutation: ({ offerId }: { offerId: Offer['id'] }) => void,
): ColumnsType<OfferDataType> => [
  {
    title: 'Index',
    dataIndex: 'index',
    render: (text) => <p>{text}</p>,
    width: 80,
  },
  {
    title: 'Order in offers page',
    dataIndex: 'order',
    render: (order) => <p>{order}</p>,
    width: 100,
  },
  {
    title: 'Image Small',
    dataIndex: 'imageSmall',
    width: 120,

    render: (imageSmall) => (
      <Space>
        <Avatar size={64} src={`${ImgPrefix}${imageSmall}`} shape="square" />
      </Space>
    ),
  },
  {
    title: 'Image Large',
    dataIndex: 'imageLarge',
    width: 120,

    render: (imageLarge) => (
      <Space>
        <Avatar size={64} src={`${ImgPrefix}${imageLarge}`} shape="square" />
      </Space>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p>{name}</p>,
    width: 190,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (description) => <p>{description}</p>,
    width: 500,
  },
  {
    title: 'Url',
    dataIndex: 'url',
    width: 400,
    render: (url) => <p>{url}</p>,
  },
  {
    title: 'For page',
    dataIndex: 'page',
    width: 400,
    render: (page) => <p>{pageMapping(page)}</p>,
  },
  {
    title: 'Is Active',
    dataIndex: 'active',
    width: 100,
    render: (_, record) => {
      return (
        <Checkbox
          defaultChecked={record.active}
          onChange={() => {
            useToggleActiveMutation({ offerId: record.key });
          }}
        />
      );
    },
  },
  {
    title: 'Edit',
    dataIndex: 'key',
    render: (offerId) => (
      <a href={`/offers/edit/${offerId}`}>
        <Flex gap="small" wrap="wrap">
          <Button
            type="link"
            style={{ backgroundColor: 'blue', color: 'white' }}
          >
            Edit offer
          </Button>
        </Flex>
      </a>
    ),
    width: 190,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString('pl')}</p>,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: 190,
    render: (createdAt) => <p>{new Date(createdAt).toLocaleString('pl')}</p>,
  },
];

export const OffersTable = ({
  columnData2,
}: {
  columnData2: OfferDataType[];
}) => {
  const [toggleActiveMutation] = useToggleActiveMutation();
  return (
    <Table
      scroll={{ y: 1000 }}
      columns={columns(toggleActiveMutation)}
      dataSource={[...columnData2]}
      pagination={{ pageSize: 20 }}
    />
  );
};
