import React, { useState } from 'react';
import { Button, DatePicker, Form, TimePicker } from 'antd';
import { Header } from '@/layout/Header';
import { useNavigate } from 'react-router-dom';
import { useCreateMatchMutation } from '@/api/matches';
import { Select } from 'antd';
import { useGetTeamsQuery } from '@/api/teams';
import { Avatar, Space } from 'antd';
import { Team } from '@/types/team';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { useGetMapsQuery } from '@/api/maps';

export const getDateString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const yearMonthDay = `${year}-${month}-${day}`;
  return yearMonthDay;
};

export type MatchFieldType = {
  date: string;
  time: string;
  team1Id: string;
  team2Id: string;
  mapId: string;
};

export const AddMatch: React.FC = () => {
  const [createMatch] = useCreateMatchMutation();
  const { data: mapsData } = useGetMapsQuery();
  const navigate = useNavigate();
  const { data: teamsData } = useGetTeamsQuery({ skip: 0, take: 1000 });
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [selectedSecondTeamId, setSelectedSecondTeamId] = useState<
    string | null
  >(null);

  const onFinish = async (values: MatchFieldType) => {
    const matchDate = new Date(values.date);
    const time = new Date(values.time);
    matchDate.setHours(time.getHours(), time.getMinutes(), 0, 0);

    await createMatch({ ...values, date: matchDate.toString() });
    navigate('/matches/all');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const team1Options =
    teamsData?.teams
      .map(({ id, name, logo }) => ({
        id,
        value: id,
        searchString: name,
        label: (
          <Space>
            <Space>
              <Avatar
                shape="square"
                size={64}
                src={`${MEDIA_FILE_URL}/${logo}`}
                style={{ marginRight: 8 }}
              />
            </Space>
            {name}
          </Space>
        ),
      }))
      .filter((team) => team.id !== selectedSecondTeamId) ?? [];

  const team2Options =
    teamsData?.teams
      .map(({ id, name, logo }) => ({
        id,
        value: id,
        searchString: name,
        label: (
          <Space>
            <Space>
              <Avatar
                shape="square"
                size={64}
                src={`${MEDIA_FILE_URL}/${logo}`}
                style={{ marginRight: 8 }}
              />
            </Space>
            {name}
          </Space>
        ),
      }))
      .filter((team) => team.id !== selectedTeamId) ?? [];

  const mapsOptions =
    mapsData?.maps
      .map(({ id, name, photo }) => ({
        id,
        value: id,
        searchString: name,
        label: (
          <Space>
            <Space>
              <Avatar
                shape="square"
                size={64}
                src={`${MEDIA_FILE_URL}/${photo}`}
                style={{ marginRight: 8 }}
              />
            </Space>
            {name}
          </Space>
        ),
      }))
      .filter((team) => team.id !== selectedTeamId) ?? [];

  const filterOption = (input: string, option?: { searchString: string }) => {
    return (option?.searchString ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Header header="Create match" />
        </Form.Item>

        <Form.Item<MatchFieldType>
          label="Date"
          name="date"
          rules={[{ required: true, message: 'Please input match date' }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item<MatchFieldType>
          label="Time"
          name="time"
          rules={[{ required: true, message: 'Please input match time' }]}
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          label="Team 1"
          name="team1Id"
          rules={[{ required: true, message: 'Please select first team' }]}
        >
          <Select
            placeholder="Select 1 team"
            style={{ height: 76 }}
            onChange={(teamId: Team['id']) => {
              setSelectedTeamId(teamId);
            }}
            showSearch
            filterOption={filterOption}
            options={team1Options}
          ></Select>
        </Form.Item>

        <Form.Item
          label="Team 2"
          name="team2Id"
          rules={[{ required: true, message: 'Please select second team' }]}
        >
          <Select
            placeholder="Select 2 team"
            disabled={!selectedTeamId}
            style={{ height: 76 }}
            onChange={(teamId: Team['id']) => {
              setSelectedSecondTeamId(teamId);
            }}
            showSearch
            filterOption={filterOption}
            options={team2Options}
          ></Select>
        </Form.Item>

        <Form.Item
          label="Map"
          name="mapId"
          rules={[{ required: true, message: 'Please select map' }]}
        >
          <Select
            placeholder="Select map"
            style={{ height: 76 }}
            showSearch
            filterOption={filterOption}
            options={mapsOptions}
          ></Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: 'green' }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
