import { Header } from '@/layout/Header';
import { DataType, MatchesTable } from './MatchesTable';
import { useGetMatchesQuery } from '@/api/matches';
import { MEDIA_FILE_URL } from '@/CONSTS';
import { useState } from 'react';

export const Matches = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: matchesData } = useGetMatchesQuery({
    skip: (page - 1) * pageSize,
    take: pageSize,
  });

  const matches = matchesData?.matches || [];
  const columnData = matches.map(
    ({ id, date, matchTeam, map }, i): DataType => {
      const team1 = matchTeam[0].team;
      const team2 = matchTeam[1].team;

      return {
        key: id,
        index: i + 1,
        date,
        team1: {
          logo: team1!.logo && `${MEDIA_FILE_URL}/${team1!.logo}`,
          name: team1!.name,
        },
        team2: {
          logo: team2!.logo && `${MEDIA_FILE_URL}/${team2!.logo}`,
          name: team2!.name,
        },
        map: {
          photo: !!map?.photo ? `${MEDIA_FILE_URL}/${map!.photo}` : '',
          name: map?.name ?? null,
        },
      };
    },
  );

  return (
    <>
      <Header header="Matches" />
      <MatchesTable
        setPage={setPage}
        setPageSize={setPageSize}
        columnData={columnData}
        pagination={matchesData?.count ?? 0}
      />
    </>
  );
};
