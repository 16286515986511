import { FC, MouseEventHandler, useState } from 'react';
import { Button, message, notification, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Thumbnail } from './Thumbnail';

const MAX_FILE_SIZE = 2 * 1024 * 1024;

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

type ImageUploaderProps = {
  onChange?: () => void;
  id?: string;
  value?: string;
  currentFileImgUrl?: string;
  aspectRatio?: number;
  label?: string;
};

export const ImageUploader: FC<ImageUploaderProps> = ({
  onChange,
  currentFileImgUrl,
  label = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [imageFileBase64, setImageFileBase64] = useState<string>();
  const [currentImageUrl, setCurrentImageUrl] = useState<string | undefined>(
    currentFileImgUrl,
  );

  const formData = new FormData();

  const beforeUpload = (file: RcFile) => {
    setLoading(true);

    if (file.size > MAX_FILE_SIZE) {
      notification.error({
        description: `Max size is 2MB`,
        message: 'File is to large',
      });
      setLoading(false);
      return;
    }

    const supportedMimeTypes = [
      'image/svg+xml',
      'image/png',
      'image/webp',
      'image/avif',
    ];

    const isSupportedFileFormat = supportedMimeTypes.includes(file.type);
    if (!isSupportedFileFormat) {
      message.error(`Not supported type: ${file.type}`);
      setLoading(false);
      return;
    }
    formData.append('logo', file);

    blobToBase64(file).then((base64File: any) => {
      setImageFileBase64(base64File);
      // @ts-ignore
      onChange?.(file);
    });

    setLoading(false);
    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemoveCurrentImage: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = (event) => {
    setCurrentImageUrl('');
    setImageFileBase64('');
    event.preventDefault();
  };

  const imageUrl = currentImageUrl || imageFileBase64;

  return (
    <div>
      {label}
      <div>
        {!currentImageUrl && (
          <Upload
            beforeUpload={beforeUpload}
            className="avatar-uploader"
            listType="picture-card"
            name="thumbnail"
            showUploadList={false}
            onRemove={() => setImageFileBase64('')}
          >
            {imageUrl ? <Thumbnail src={imageUrl} /> : uploadButton}
          </Upload>
        )}
      </div>
      <p style={{ fontSize: 12 }}>Info: max file size is 2MB</p>
      <p style={{ fontSize: 12 }}>
        Recommendation: Upload svg, webp, png and avif files to achieve the best
        performance
      </p>
      {currentImageUrl && (
        <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
          <span className="ant-upload">
            <Thumbnail src={currentImageUrl} />
          </span>
        </div>
      )}
      {imageUrl && (
        <Button
          className="image-uploader__remove-button"
          onClick={handleRemoveCurrentImage}
        >
          Remove
        </Button>
      )}
    </div>
  );
};
