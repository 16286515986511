import { Match } from '@/types/match';
import { api } from './api';
import { MatchFieldType } from '@/components/match/AddMatch';

const baseUrl = '/matches';

export type Pagination = { skip: number; take: number };

const matchesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMatches: builder.query<{ matches: Match[]; count: number }, Pagination>({
      query: ({ skip, take }) => `${baseUrl}?skip=${skip}&take=${take}`,
    }),
    getMatchesForDemoUpload: builder.query<{ matches: Match[] }, void>({
      query: () => `${baseUrl}/demo`,
    }),
    createMatch: builder.mutation<{ match: Match }, MatchFieldType>({
      invalidatesTags: ['Match'],
      query: (body) => ({ url: `${baseUrl}`, body, method: 'POST' }),
    }),
    getMatchById: builder.query<{ match: Match }, { matchId: Match['id'] }>({
      query: ({ matchId }) => `${baseUrl}/${matchId}`,
    }),
    updateMatch: builder.mutation<
      { match: Match },
      { mapId: string; matchId: string; date: Date }
    >({
      query: ({ matchId, ...body }) => ({
        url: `${baseUrl}/${matchId}`,
        body,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = matchesApi.endpoints;
export const {
  useGetMatchesQuery,
  useCreateMatchMutation,
  useGetMatchesForDemoUploadQuery,
  useGetMatchByIdQuery,
  useUpdateMatchMutation,
} = matchesApi;
