import { Header } from '@/layout/Header';
import { DataType, TeamsTable } from './TeamsTable';
import { useGetTeamsQuery } from '@/api/teams';
import { useState } from 'react';

export const Teams = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: teamsData } = useGetTeamsQuery({
    skip: (page - 1) * pageSize,
    take: pageSize,
  });

  const teams = teamsData?.teams || [];
  const columnData = teams.map(
    ({ id, name, logo }, i): DataType => ({
      key: id,
      index: i + 1,
      name,
      logo,
    }),
  );

  return (
    <>
      <Header header="Teams" />
      <TeamsTable
        columnData={columnData}
        setPageSize={setPageSize}
        setPage={setPage}
        pagination={teamsData?.count ?? 0}
      />
    </>
  );
};
