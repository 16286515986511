import { logout } from '@/slices/credentials/credentialsSlice';
import { Layout } from 'antd';
import { Button, Flex } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signOut } from 'supertokens-auth-react/recipe/session';

export const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await signOut();
    dispatch(logout());
    navigate('/auth');
  };

  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      <Flex wrap="wrap" gap="small" justify="center" align="center">
        <p>Gamerinsight - Admin Panel</p>
        <Button type="primary" danger onClick={handleLogOut}>
          Log out
        </Button>
      </Flex>
    </Layout.Footer>
  );
};
